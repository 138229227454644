.loginWrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginWrapper > * {
  margin: 10px;
}
