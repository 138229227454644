.global {
  font-size: 20px;
  min-width: 300px;
}

.flexPage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.flexPageSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  margin: 20px;
  min-width: 300px;
}

.flexPageSectionTitle {
  text-align: center;
  margin: 10px;
}

.controlButton {
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  border-color: black;
}

.controlButton:hover {
  box-shadow: 0px 0px 10px;
}
